import React, { useState, useEffect } from "react"
import styled from "styled-components"

const WidgetCTA = ({ formattedCTAQuery, ctaText, location }) => {
  const fullURL = "https://www.fubosportsnetwork.com"

  return (
    <ButtonWrapper className="heroCtaWrapper">
      <a href={fullURL} target="_top">
        Watch on Fubo
      </a>
    </ButtonWrapper>
  )
}

export default WidgetCTA

const ButtonWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto 30px auto;
  background: #fa4616;
  color: #fff;

  @media (max-width: 600px) {
    margin-bottom: 40px;
  }

  a {
    display: block;
    font-size: 23px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    background: #fa4616;
    padding: 18px 20px;
    border-radius: 3px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;

    :hover {
      background: #b33922;
      cursor: pointer;
    }

    @media screen and (max-width: 736px) and (min-width: 320px) {
      font-size: 20px;
    }
  }
`
